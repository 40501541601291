import React from 'react';
import tabBg2 from 'imgs/1d1d/pc/ketizu/about_tab_bg2.png';
import styled from 'styled-components';
import { BgWrap } from '../__styled';

const ContentWrap = styled.div`
  font-size: 0.9rem;
  color: #565656;
  line-height: 1.6rem;
  display: flex;
  flex-wrap: wrap;
  font-family: Microsoft YaHei;
  font-weight: 400;
  .expert-item {
    display: flex;
    flex: auto;
    margin-bottom: 1rem;
    .expert-item-info-p {
      width: 100%;
    }
    & > img {
      width: 4.51rem;
      height: 4.51rem;
      display: block;
      margin-right: 0.85rem;
    }
    &-info {
      pre {
        font-size: 1rem;
        font-family: MicrosoftYaHei;
        font-weight: bold;
        color: #424242;
      }
      &-p {
        > p {
          color: #565656;
        }
        font-size: 0.6rem;
        line-height: 1.29rem;
      }
    }
  }
`;

const data = [
  {
    name: '孙霄兵',
    headImg: 'https://file.1d1d100.com/2021/1/5/67398cd6ce4143c799e10435711c12e3.png',
    positions: ['教育部政策研究与法制建设司原司长', '中国教育发展战略学会执行会长']
  },
  {
    name: '杨春茂',
    headImg: 'https://file.1d1d100.com/2021/1/5/4cb5c2a234f743b1a16a02f3d566e649.png',
    positions: [
      '中国教师发展基金会原秘书长',
      '中国教育学会监事长',
      '中国教育发展战略学会教师发展专业委员会学术委员会主任'
    ]
  },
  {
    name: '雷振海',
    headImg: 'https://file.1d1d100.com/2021/1/5/54f209208a4547f385ee7b702079d04f.png',
    positions: [
      '中国教育报刊社副社长',
      '中国教师报总编辑',
      '中国教育发展战略学会常务理事',
      '中国教育发展战略学会教师发展专业委员会理事长'
    ]
  },
  {
    name: '姜志伟',
    headImg: 'https://file.1d1d100.com/2021/1/5/da2605768ce44eb2972ec3d56fd5957f.png',
    positions: [
      '中国教育发展战略学会常务理事',
      '中国教育发展战略学会教师发展专业委员会副理事长兼秘书长'
    ]
  },
  {
    name: '缪建东',
    headImg: 'https://file.1d1d100.com/2021/1/5/465f264a5d324480afdfd514a7c64c9b.png',
    positions: ['南京师范大学副校长', '中国教育发展战略学会教师发展专业委员会副理事长']
  },
  {
    name: '王红兵',
    headImg: 'https://file.1d1d100.com/2021/1/5/fd8f585ee9ab4824a595c9023e90a61b.png',
    positions: [
      '中国教育发展战略学会理事',
      '中国教育发展战略学会教师发展专业委员会副理事长',
      '教师发展专业委员会学前教育中心主任'
    ]
  },
  {
    name: '董洪丹',
    headImg: 'https://file.1d1d100.com/2021/1/5/810a091312e2402a828336580362bb00.png',
    positions: [
      '四川省教育科学研究院副院长',
      '中国教育发展战略学会理事',
      '中国教育发展战略学会教师发展专业委员会副理事长'
    ]
  },
  {
    name: `何   锋`,
    headImg: 'https://file.1d1d100.com/2021/1/5/eaa2a2f5a3214e43ac988b7f48e5f919.png',
    positions: [
      '江苏省教科院党委副书记、教研员',
      '中国教育发展战略学会理事',
      '中国教育发展战略学会教师发展专业委员会副理事长'
    ]
  },
  {
    name: '李   广',
    headImg: 'https://file.1d1d100.com/2021/1/5/b5d5014c74dd4cdd8a33045354d37d94.png',
    positions: ['东北师范大学教师发展中心主任', '中国教育发展战略学会教师发展专业委员会副理事长']
  },
  {
    name: '刘旭东',
    headImg: 'https://file.1d1d100.com/2021/1/5/b8f006f361b04f1386580a300e390f04.png',
    positions: ['西北师范大学继续教育学院院长', '中国教育发展战略学会教师发展专业委员会副理事长']
  },
  {
    name: '郭   峰',
    headImg: 'https://file.1d1d100.com/2021/1/5/c7c1a88c8a6e4b9fbf4b1de0376c8d9f.png',
    positions: ['济南大学教师发展中心主任', '中国教育发展战略学会教师发展专业委员会副理事长']
  },
  {
    name: '吕梦訢',
    headImg: 'https://file.1d1d100.com/2021/1/5/3f4ccaf890174f9d9d75974b1c24f61b.png',
    positions: [
      '中国教育发展战略学会理事',
      '中国教育发展战略学会教师发展专业委员会副秘书长（住会）'
    ]
  },
  {
    name: '石   洁',
    headImg: 'https://file.1d1d100.com/2021/1/5/1e3ed13132374c75a3923ab9c238023a.png',
    positions: ['中国教育发展战略学会理事', '中国教育发展战略学会教师发展专业委员会副秘书长']
  },
  {
    name: '谷   岩',
    headImg: 'https://file.1d1d100.com/2021/1/26/b6a84d3b6c35482bb4e519717822903b.png',
    positions: [
      '教师发展专业委员会学前教育中心研究员',
      `“信息化背景下的家庭教育研究” 课题组教研专家`
    ]
  },
  {
    name: '侯亚丽',
    headImg: 'https://file.1d1d100.com/2021/1/26/6acea19a168046c6a6d4d18855d50542.png',
    positions: [
      '教师发展专业委员会学前教育中心研究员',
      `“信息化背景下的家庭教育研究” 课题组教研专家`
    ]
  }
];

export default function TabItem2({ id }) {
  return (
    <div className="tab-content-item" id={id} data-index="1">
      <BgWrap bg={tabBg2} />
      <div className="info-box">
        <h1 className="info-box-title">专家团队 </h1>
        <ContentWrap>
          {data.map(({ name, headImg, positions = [] }) => (
            <div className="expert-item" key={name}>
              <img src={headImg} alt="" />
              <div className="expert-item-info">
                <pre>{name}</pre>
                <div className="expert-item-info-p">
                  {positions.map((p) => (
                    <p className="p" key={p}>
                      {p}
                    </p>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </ContentWrap>
        <a className="info-box-btn" href="https://www.wjx.cn/jq/102693804.aspx">
          申报课题实验基地
        </a>
      </div>
    </div>
  );
}
