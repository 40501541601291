import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > img {
    width: 100%;
    z-index: -1;
    height: 400px;
    object-fit: cover;
  }
  .content {
    width: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: Microsoft YaHei;
    font-weight: 400;
    .title-box {
      h1 {
        font-size: 2.4em;
        line-height: 1;
        color: #ffffff;
        margin-bottom: 1.5rem;
      }
      h2 {
        font-size: 0.9rem;
        color: #ffffff;
        line-height: 1.4rem;
      }
    }
  }
`;

export default function Banner({ banner }) {
  return (
    <Wrapper>
      <img src={banner} alt=" " />
      <div className="content">
        <div className="title-box">
          <h1>关于课题</h1>
          <h2>
            为贯彻落实教育部《关于加强家庭教育工作的指导意见》要求，中国教育发展战略学会教师发展专业委员会拟在“十四五”期间（2021—2025年），计划在全国部分幼儿园开展“信息化背景下的家庭教育研究”。
          </h2>
        </div>
      </div>
    </Wrapper>
  );
}
